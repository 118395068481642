<template>
  <div class="vertical-layout s-detail-block">
    <div>
      <shipment-detail-header :shipment="shipment" />
    </div>
    <div>
      <shipment-detail-form :shipment="shipment" />
    </div>
    <the-tabs @input="onTabInput">
      <b-tab-item :label="$t('shipment.tab.tracking')" value="tracking" v-if="selectedShipmentType==='railway'">
        <shipment-google-railway :waypoints="waypoints" :transportNumber="shipment?.detail?.orderTransportNumber"/>
      </b-tab-item>
      <b-tab-item :label="$t('shipment.tab.tracking')" value="tracking" v-else>
        <shipment-google-map :isOrderClosed="isSelectedOrderClosed" :orderWaypoints="waypoints" @autoTrackingUpdate="trackingUpdate" :orderInternalId="shipment?.general?.orderInternalId" :transportNumber="shipment?.detail?.orderTransportNumber"/>
      </b-tab-item>
      <b-tab-item :label="$t('shipment.tab.detail')">
        <shipment-detail-detail :shipment="shipment" />
      </b-tab-item>
      <b-tab-item :label="$t('shipment.tab.history')">
        <shipment-detail-history :shipmentHistory="shipmentHistory" />
      </b-tab-item>
      <b-tab-item :label="$t('shipment.tab.requests')">
        <shipment-detail-requests :conversations="shipment?.requests"></shipment-detail-requests>
      </b-tab-item>
      <b-tab-item :label="$t('shipment.tab.documents')">
        <shipment-detail-documents :documents="shipment?.documents" />
      </b-tab-item>      
    </the-tabs>
    <div class="mobile-panels">
      <the-collapsing-panel :title="$t('shipment.tab.tracking')" @collapseToggle="onCollapseToggle" v-if="selectedShipmentType==='railway'">
        <shipment-google-railway :waypoints="waypoints" :transportNumber="shipment?.detail?.orderTransportNumber"/>
      </the-collapsing-panel>
      <the-collapsing-panel :title="$t('shipment.tab.tracking')" @collapseToggle="onCollapseToggle" v-else>
        <shipment-google-map :isOrderClosed="isSelectedOrderClosed" :orderWaypoints="waypoints" @autoTrackingUpdate="trackingUpdate" :orderInternalId="shipment?.general?.orderInternalId" :transportNumber="shipment?.detail?.orderTransportNumber"/>
      </the-collapsing-panel>
      <the-collapsing-panel :title="$t('shipment.tab.detail')">
        <shipment-detail-detail :shipment="shipment" />
      </the-collapsing-panel>
      <the-collapsing-panel :title="$t('shipment.tab.history')">
        <shipment-detail-history :shipmentHistory="shipmentHistory" />
      </the-collapsing-panel>
      <the-collapsing-panel :title="$t('shipment.tab.requests')">
        <shipment-detail-requests :conversations="shipment?.requests"></shipment-detail-requests>
      </the-collapsing-panel>
      <the-collapsing-panel :title="$t('shipment.tab.documents')">
        <shipment-detail-documents :documents="shipment?.documents" />
      </the-collapsing-panel>
    </div>
  </div>
</template>

<script>

import ShipmentDetailForm from "@/components/shipment/ShipmentDetailForm.vue";
import TheTabs from "@/components/TheTabs.vue";
import ShipmentDetailDetail from "@/components/shipment/ShipmentDetailDetail.vue";
import TheCollapsingPanel from "@/components/commonLib/TheCollapsingPanel.vue";
import ShipmentDetailHeader from "@/components/shipment/ShipmentDetailHeader.vue";
import ShipmentDetailHistory from "@/components/shipment/ShipmentDetailHistory.vue";
import ShipmentDetailDocuments from "@/components/shipment/ShipmentDetailDocuments.vue";
import ShipmentDetailRequests from "@/components/shipment/ShipmentDetailRequests.vue";
import ShipmentGoogleMap from "@/components/shipment/ShipmentGoogleMap.vue";
import ShipmentGoogleRailway from "@/components/shipment/ShipmentGoogleRailway.vue";

export default {
  components: {
    ShipmentDetailForm,
    TheTabs,
    ShipmentDetailDetail,
    TheCollapsingPanel,
    ShipmentDetailHeader,
    ShipmentDetailHistory,
    ShipmentDetailDocuments,
    ShipmentDetailRequests,
    ShipmentGoogleMap,
    ShipmentGoogleRailway
  },
  props: {
    shipment: {
      type: Object
    },
    selectedShipmentType: {
      type: String
    },
    waypoints: {
      type: Array
    },
    isSelectedOrderClosed: {
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  computed: {
    shipmentHistory() {
      return this.sortTracking(this.shipment?.history, "statusNumber");
    }
  },
  methods: {
    //отправка запроса на трекинг только при нажатии на вкладку Отслеживание
    onTabInput(newValue) {
      if (newValue === "tracking") {
        this.trackingUpdate();
      }
    },
    onCollapseToggle(bValue) {
      
      if (bValue) {
        this.trackingUpdate();
      }
    },
    trackingUpdate() {
      this.$emit('updateTracking');
    }
  }
};
</script>

<style lang="scss">
.s-detail-block {
  overflow: auto;
  overflow: auto;
  overflow-x: hidden;
  padding: 0.1rem;
  height: 100%;
}

.mobile-panels {
  display: none;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .s-detail-block .custom-tabs {
    display: none;
  }
  .mobile-panels {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .s-detail-block .custom-tabs {
    display: none;
  }
  .mobile-panels {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .wrapping-form-block {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    .wrap-form {
      width: 48%;
    }
    .wrap-form-full {
      width: 100%;
    }
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .wrapping-form-block {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    .wrap-form {
      width: 48%;
    }
    .wrap-form-full {
      width: 100%;
    }
  }
}
</style>
