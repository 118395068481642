<template>
<div>
  <a class="list-item" @click="itemClick">
    <div class="state" :class="getStatusClass(shipment?.orderActualStatus.statusId, shipment?.orderArrivalDateActual)" />
    <div class="item-info" :class="shipment?.orderActualStatus.statusId === 'READY' ? 'success' : ''">
      <div class="item-info_main fiveColumn">
        <p class="bold">{{ formatTextField(shipment?.orderInternalId) }}</p>
        <p>{{ shipment?.orderFrom }} - {{ shipment?.orderTo }}</p>
        <p>{{ shipment?.orderCargoName }}</p>
        <!-- <p>{{ shipment?.orderShipper }}</p> -->
      </div>
      <div class="fiveColumn">
        <div class="form-item">
          <label>{{ $t("shipment.label.transportNumb") }}</label>
          <div class="bold">{{ formatTextField(shipment?.transportNumber) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.status") }}</label>
          <div>{{ formatTextField(shipment?.orderActualStatus.statusText) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.currentLocation") }}</label>
          <div>{{ formatTextField(shipment?.currentLocation) }}</div>
        </div>
      </div>
      <div class="fiveColumn">
        <div class="form-item">
          <label>{{ $t("shipment.label.shipmentKmLeft") }}</label>
          <div>{{ formatTextField(shipment?.orderKmLeft) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.shipmentDaysLeft") }}</label>
          <div>{{ formatTextField(shipment?.orderDaysLeft) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.comment") }}</label>
          <div class="comment-container">
            <div class="text-container" :class="{ 'is-truncated': isNeedTruncated && isTextTruncated }">{{ formatTextField(shipment?.commentForClient) }}</div>
            <a class="toggle-btn" v-if="isNeedTruncated && isTextTruncated" @click="showFullText">{{ $t("btn.showMore") }}</a>
            <a class="toggle-btn" v-if="isNeedTruncated && !isTextTruncated" @click="hideFullText">{{ $t("btn.hide") }}</a>
          </div>          
        </div>
      </div>
      <div class="fiveColumn">
        <div class="form-item" v-if="shipment?.orderDepartureDateActual">
          <label>{{ $t("shipment.label.shipmentDateFact") }}</label>
          <div>{{ formatDateField(shipment?.orderDepartureDateActual) }}</div>
        </div>
        <div class="form-item" v-else>
          <label>{{ $t("shipment.label.shipmentDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.orderDepartureDatePlan) }}</div>
        </div>
        <div class="form-item" v-if="shipment?.orderArrivalDateActual">
          <label>{{ $t("shipment.label.arrivalDateFact") }}</label>
          <div>{{ formatDateField(shipment?.orderArrivalDateActual) }}</div>
        </div>
        <div class="form-item" v-else>
          <label>{{ $t("shipment.label.arrivalDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.orderArrivalDatePlan) }}</div>
        </div>
      </div>
      <div class="fiveColumn">
        <div class="form-item">
          <label>{{ $t("shipment.label.invoices") }}</label>
          <div>{{ formatTextField(shipment?.orderInvoices.join(', ')) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.orderID") }}</label>
          <div>{{ formatTextField(shipment?.orderNumber) }}</div>
        </div>
        <div class="form-item" v-if="shipment?.orderActualStatus.statusId === 'READY'">
          <label v-if="!shipment.orderRated">{{ $t("shipment.label.rating") }}</label>
          <div v-if="!shipment.orderRated" class="feedback-rating" >
            
            <the-rate :rating="shipmentRate" @rateChanged="rateChanged" />
          </div>
          <div v-else>
          <label >{{ $t("shipment.label.ratingCompleted") }}</label>
        </div>
        </div>
        <!-- <div class="form-item">
          <label>{{ $t("shipment.label.manager") }}</label>
          <div>{{ formatTextField(shipment?.orderKAM) }}</div>
        </div> -->
      </div>
    </div>
  </a>
  <the-popup ref="popup" :closable="true" height="21.5rem" @closeBtnPress="cancelRatingDialog" emitNameOnCloseEvent='closeBtnPress'>
    <shipment-rate-dialog size="is-small" :rate="shipmentRate" :shipmentId="shipment.orderId" @closePopup="closeRatingDialog" />
  </the-popup>
</div>
</template>


<script>
import MockService from "@/services/MockService";
import TheRate from "@/components/commonLib/TheRate.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import ShipmentRateDialog from "@/components/shipment/ShipmentRateDialog.vue";
export default {
  components: {
    TheRate,
    ThePopup,
    ShipmentRateDialog
  },
  props: {
    shipment: {
      type: Object,
    },
  },
  data() {
    return {
      shipmentRate: 0,
      isTextTruncated: true,
      isNeedTruncated: this.shipment?.commentForClient?.length > 50
    };
  },
  methods: {
    rateChanged(value) {
      this.shipmentRate = value;
      this.$refs.popup.open();
    },
    itemClick(e) {
      this.$emit('onShipmentClick', this.shipment.orderId)
    },
    closeRatingDialog() {
      this.$refs.popup.close();
      this.$emit('shipmentsUpdate');
    },
    cancelRatingDialog() {
      this.shipmentRate = 0;
    },
    showFullText(e) {
      e.stopPropagation();
      this.isTextTruncated = false;
    },
    hideFullText(e) {
      e.stopPropagation();
      this.isTextTruncated = true;
    }
  }
};
</script>

<style lang="scss">
.feedback-rating {
  .icon.is-small {
    width: 1.3rem;
    svg {
      height: 1.2rem;
    }
  }
}
.comment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.text-container {
  overflow: hidden;
  white-space: wrap;
  width: 100%;
}
.text-container.is-truncated {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toggle-btn {
  margin-top: 0.2rem;
  color: #2c3e50
}
.toggle-btn:hover {
  margin-top: 0.2rem;
  color: #0b1014
}
</style>
